.margin-top {
  margin-top: 10px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.margin-right {
  margin-right: 10px;
}
.margin-left {
  margin-left: 10px;
}

.margin-top-2x {
  margin-top: 20px;
}
.margin-bottom-2x {
  margin-bottom: 20px;
}
.margin-right-2x {
  margin-right: 20px;
}
.margin-left-2x {
  margin-left: 20px;
}

.no-margin {
  margin: 0;
}
.no-margin-top {
  margin-top: 0;
}
.no-margin-bottom {
  margin-bottom: 0;
}
.no-margin-left {
  margin-left: 0;
}
.no-margin-right {
  margin-right: 0;
}

.heading-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.padding-top {
  padding-top: 10px;
}
.padding-bottom {
  padding-bottom: 10px;
}
.padding-right {
  padding-right: 10px;
}
.padding-left {
  padding-left: 10px;
}

.padding-top-2x {
  padding-top: 20px;
}
.padding-bottom-2x {
  padding-bottom: 20px;
}
.padding-right-2x {
  padding-right: 20px;
}
.padding-left-2x {
  padding-left: 20px;
}

.no-padding {
  padding: 0;
}
.no-padding-top {
  padding-top: 0;
}
.no-padding-bottom {
  padding-bottom: 0;
}
.no-padding-left {
  padding-left: 0;
}
.no-padding-right {
  padding-right: 0;
}

// center-block for right aligning
.right-block {
  text-align: right;
  display: block;
}
