@import (reference) "./_variables_colors.less";
@import (reference) '_fonts.less';

.default-md {
  border-radius: 19px;
  padding-top: 7px;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.default-primary {
  background-color: @primary-teal;
  border: 2px solid @primary-teal;
  color: @white;

  &:hover, &:active {
    background-color: @hover-teal;
    border-color: @hover-teal;
    color: @white;
  }

  a { color: @white; }
}

.btn, input.btn, div.btn, a.btn {
  /* Use of just .btn will default to settings for .btn-primary and .btn-md */
  font-family: @body-font-family;
  font-size: 12px;//rems??
  letter-spacing: 1.1px;
  line-height: 22px;
  padding-left: 40px;
  padding-right: 40px;
  transition: all 0.5s;
  transition-property: background, border;
  .default-md();
  .default-primary();

  i {
    font-size: 20px;//rems??
    line-height: 20px;
    float: left;
    margin-right: 5px;
    top: 0px;
  }

  &[disabled],.disabled {
    background-color: @outline-gray;
    border-color: @outline-gray;
    color: @secondary-gray;
    pointer-events: none;
  }

  &.btn-circle {
    border-radius: 50%;
    background-color: #fff;
    height: 60px;
    padding: 0;
    text-align: center;
    width: 60px;

    i {
      color: @primary-teal;
      font-size: 25px;//rems??
      line-height: 60px;
    }
  }
  @media(max-width: @screen-xs-max) {
    // make buttons large targets on mobile
    border-radius: 23px;
    padding-top: 10px;
    padding-bottom: 9px;
  }

  &.btn-primary {
    .default-primary();
  }

  &.btn-secondary {
    background-color: @white;
    border-color: @primary-teal;
    color: @primary-teal;
    transition: background-color 0.5s;

    &:hover, &:active {
      color: @white;
      background-color: @primary-teal;
    }
  }

  &.btn-sm {
    border-radius: 16px;
    padding-top: 4px;
    padding-bottom: 2px;
  }

  &.btn-md {
    .default-md();
  }

  &.btn-lg {
    border-radius: 23px;
    padding-top: 11px;
    padding-bottom: 8px;
  }

  &.btn-icon {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.btn-link {
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: @brand-primary;
}

.next-btn,
.previous-btn {
  margin-top: 11px;
}

// the mixin will default to 8px for padding top and padding bottom unless specified
.unified-nav__btn(@padding-top-bottom: 8px) {
  background-color: @brand-primary;
  border-radius: 100px;
  color: @white;
  letter-spacing: 1.1px;
  line-height: 14px;
  padding-bottom: @padding-top-bottom;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: @padding-top-bottom;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    background-color: @hover-teal;
    cursor: pointer;
  }
  a {
    color: @white;
    &:hover {
      text-decoration: none;
    }
  }
  .top-nav__link {
    letter-spacing: 1.1px;
  }
}

.unified-nav__btn-secondary(@padding-top-bottom: 8px) {
  background-color: @white;
  border-radius: 100px;
  color: @brand-primary;
  letter-spacing: 1.1px;
  line-height: 14px;
  padding-bottom: @padding-top-bottom;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: @padding-top-bottom;
  text-align: center;
  text-transform: uppercase;
  border: 2px solid @brand-primary;
  &:hover {
    background-color: @brand-primary;
    color: @white;
    cursor: pointer;
  }
  a {
    color: @white;
    &:hover {
      text-decoration: none;
    }
  }
  .top-nav__link {
    letter-spacing: 1.1px;
  }
}
