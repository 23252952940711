// a hacky way to deal with icon
@font-path: 'https://d1tntvpcrzvon2.cloudfront.net/vrassets/static/media/';
@font-face {
  font-family: 'zolaicons';
  // src: url('@{font-path}zolaicons.eot?24613844');
  // src: url('@{font-path}zolaicons.eot?24613844#iefix') format('embedded-opentype'),
  //   url('@{font-path}zolaicons.woff2?24613844') format('woff2'),
  //   url('@{font-path}zolaicons.woff?24613844') format('woff'),
  //   url('@{font-path}zolaicons.ttf?24613844') format('truetype'),
  //   url('@{font-path}zolaicons.svg?24613844#zolaicons') format('svg');
  src: url('@{font-path}zolaicons.b9d8cd80.eot');
  src: url('@{font-path}zolaicons.b9d8cd80.eot#iefix') format('embedded-opentype'),
    url('@{font-path}zolaicons.e7ea0bff.woff2') format('woff2'),
    url('@{font-path}zolaicons.ece7538c.woff') format('woff'),
    url('@{font-path}zolaicons.ba3b8d8b.ttf') format('truetype'),
    url('@{font-path}zolaicons.ad21eb34.svg#zolaicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'zolaicons';
    src: url('../font/zolaicons.svg?24613844#zolaicons') format('svg');
  }
}
*/

[class^='zolaicon-']:before,
[class*=' zolaicon-']:before {
  font-family: 'zolaicons';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.zolaicon-mobile:before {
  content: '\e800';
} /* '' */
.zolaicon-desktop:before {
  content: '\e801';
} /* '' */
.zolaicon-arrow-down:before {
  content: '\e802';
} /* '' */
.zolaicon-arrow-up:before {
  content: '\e803';
} /* '' */
.zolaicon-bank:before {
  content: '\e804';
} /* '' */
.zolaicon-call:before {
  content: '\e805';
} /* '' */
.zolaicon-chat:before {
  content: '\e806';
} /* '' */
.zolaicon-train:before {
  content: '\e807';
} /* '' */
.zolaicon-shuttle:before {
  content: '\e808';
} /* '' */
.zolaicon-warning-sign:before {
  content: '\e809';
} /* '' */
.zolaicon-car-rental:before {
  content: '\e80a';
} /* '' */
.zolaicon-clock:before {
  content: '\e80b';
} /* '' */
.zolaicon-house-rental:before {
  content: '\e80c';
} /* '' */
.zolaicon-collection:before {
  content: '\e80d';
} /* '' */
.zolaicon-credit-card:before {
  content: '\e80e';
} /* '' */
.zolaicon-design:before {
  content: '\e80f';
} /* '' */
.zolaicon-email:before {
  content: '\e810';
} /* '' */
.zolaicon-exchange:before {
  content: '\e811';
} /* '' */
.zolaicon-gift:before {
  content: '\e812';
} /* '' */
.zolaicon-headset:before {
  content: '\e813';
} /* '' */
.zolaicon-calendar:before {
  content: '\e814';
} /* '' */
.zolaicon-invitefriend:before {
  content: '\e815';
} /* '' */
.zolaicon-folder:before {
  content: '\e816';
} /* '' */
.zolaicon-cake:before {
  content: '\e817';
} /* '' */
.zolaicon-visible:before {
  content: '\e818';
} /* '' */
.zolaicon-store:before {
  content: '\e81a';
} /* '' */
.zolaicon-star:before {
  content: '\e81b';
} /* '' */
.zolaicon-smartphone:before {
  content: '\e81c';
} /* '' */
.zolaicon-price-tag:before {
  content: '\e81d';
} /* '' */
.zolaicon-play:before {
  content: '\e81e';
} /* '' */
.zolaicon-piggybank:before {
  content: '\e81f';
} /* '' */
.zolaicon-package:before {
  content: '\e820';
} /* '' */
.zolaicon-pencil:before {
  content: '\e821';
} /* '' */
.zolaicon-notes:before {
  content: '\e822';
} /* '' */
.zolaicon-newspaper:before {
  content: '\e823';
} /* '' */
.zolaicon-move-1:before {
  content: '\e824';
} /* '' */
.zolaicon-magnifying-glass:before {
  content: '\e825';
} /* '' */
.zolaicon-info:before {
  content: '\e826';
} /* '' */
.zolaicon-heart:before {
  content: '\e827';
} /* '' */
.zolaicon-lock:before {
  content: '\e828';
} /* '' */
.zolaicon-link:before {
  content: '\e829';
} /* '' */
.zolaicon-globe:before {
  content: '\e82a';
} /* '' */
.zolaicon-group:before {
  content: '\e82b';
} /* '' */
.zolaicon-flight:before {
  content: '\e82c';
} /* '' */
.zolaicon-terminal:before {
  content: '\e82d';
} /* '' */
.zolaicon-trash:before {
  content: '\e82e';
} /* '' */
.zolaicon-truck:before {
  content: '\e82f';
} /* '' */
.zolaicon-checkmark:before {
  content: '\e830';
} /* '' */
.zolaicon-cart:before {
  content: '\e831';
} /* '' */
.zolaicon-pricematch-1:before {
  content: '\e832';
} /* '' */
.zolaicon-play-circled:before {
  content: '\e833';
} /* '' */
.zolaicon-hotel:before {
  content: '\e834';
} /* '' */
.zolaicon-chevron-down:before {
  content: '\e835';
} /* '' */
.zolaicon-chevron-left:before {
  content: '\e836';
} /* '' */
.zolaicon-chevron-right:before {
  content: '\e837';
} /* '' */
.zolaicon-chevron-up:before {
  content: '\e838';
} /* '' */
.zolaicon-clock-1:before {
  content: '\e839';
} /* '' */
.zolaicon-twitter:before {
  content: '\e83a';
} /* '' */
.zolaicon-facebook:before {
  content: '\e83b';
} /* '' */
.zolaicon-gplus:before {
  content: '\e83c';
} /* '' */
.zolaicon-pinterest-circled:before {
  content: '\e83d';
} /* '' */
.zolaicon-instagram:before {
  content: '\e83e';
} /* '' */
.zolaicon-videocam:before {
  content: '\e83f';
} /* '' */
.zolaicon-close:before {
  content: '\e840';
} /* '' */
.zolaicon-play-2:before {
  content: '\e841';
} /* '' */
.zolaicon-facebook-square:before {
  content: '\e842';
} /* '' */
.zolaicon-download-alt:before {
  content: '\e843';
} /* '' */
.zolaicon-gear:before {
  content: '\e844';
} /* '' */
.zolaicon-poi:before {
  content: '\e845';
} /* '' */
.zolaicon-rsvp:before {
  content: '\e84b';
} /* '' */
.zolaicon-wedding_party:before {
  content: '\e84d';
} /* '' */
.zolaicon-home:before {
  content: '\e84e';
} /* '' */
.zolaicon-travel:before {
  content: '\e84f';
} /* '' */
.zolaicon-note:before {
  content: '\e850';
} /* '' */
.zolaicon-faq:before {
  content: '\e853';
} /* '' */
.zolaicon-couple:before {
  content: '\e855';
} /* '' */
.zolaicon-family:before {
  content: '\e856';
} /* '' */
.zolaicon-individual:before {
  content: '\e819';
} /* '' */
.zolaicon-photo:before {
  content: '\e858';
} /* '' */
.zolaicon-registry:before {
  content: '\e859';
} /* '' */
.zolaicon-event:before {
  content: '\e85a';
} /* '' */
.zolaicon-reorder:before {
  content: '\e884';
} /* '' */
.zolaicon-profile-photo:before {
  content: '\e88e';
} /* '' */
.zolaicon-photo-upload:before {
  content: '\e88f';
} /* '' */
.zolaicon-move:before {
  content: '\f047';
} /* '' */
.zolaicon-preview:before {
  content: '\f08f';
} /* '' */
.zolaicon-gear-1:before {
  content: '\f09a';
} /* '' */
.zolaicon-right-arrow:before {
  content: '\f09b';
} /* '' */
.zolaicon-plus:before {
  content: '\f09c';
} /* '' */
