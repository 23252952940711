.has-error {
  .input-group-addon {
    color: inherit;
    background-color: @input-group-addon-bg;
  }
  .input-group {
    .help-block {
      padding: 5px;
      border: 1px solid @brand-danger;
      margin-bottom: 0;
      background: @brand-danger;
      color: @white;
    }
  }
  .icon-group {
    .help-block {
      padding: 5px;
      border: 1px solid @brand-danger;
      margin-bottom: 0;
      background: @brand-danger;
      color: @white;
      margin-top: 0px;
      text-align: left;
    }
  }
}
