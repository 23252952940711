// https://github.com/twbs/bootstrap/blob/master/less/close.less

// rather than floating the close button, absolutely position it 10px from the corner
.close {
  float: none;
  position: absolute;
  top: 5px; // seems to have built-in top padding, so reduce this
  right: 10px;
  z-index: 1; // give it a z-index so it stays above other content
}
