// https://github.com/twbs/bootstrap/blob/master/less/mixins/labels.less

// mixin to create an inverted version of a label
.label-variant-inverted(@color) {
  background-color: @label-color;
  color: @color;
  &[href] {
    &:hover,
    &:focus {
      background-color: @label-color;
      color: darken(@color, 10%);
    }
  }
}

// also override the base label-variant mixin to support .label-inverted
.label-variant(@color) {
  background-color: @color;

  &[href] {
    &:hover,
    &:focus {
      background-color: darken(@color, 10%);
    }
  }

  // also
  &.label-inverted {
    .label-variant-inverted(@color);
  }
}
