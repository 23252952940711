@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
// Forms
@input-border: @color-black-030;
@input-border-radius: 0;
@input-border-radius-small: 0;
@input-border-radius-large: 0;
@input-border-focus: @color-black-100;
@state-danger-text: @red;
@state-danger-bg: @red;
@state-danger-border: @red;

// https://github.com/twbs/bootstrap/blob/master/less/forms.less

.form-control {
  caret-color: @color-black-100;
  height: 48px;
  font-size: 16px;
  .appearance(none);
  .box-shadow(none) !important; // make sure we never see a box-shadow on our controls
  .transition(none);
  select& {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: @white;
    background: url('../../../assets/images/dropdown.svg') no-repeat right @white;
    .modernizr-csspointerevents & {
      .appearance(none);
      &::-ms-expand {
        display: none;
      }
      padding-right: @padding-base-horizontal + 26px; // add the width of the dropdown image
    }
    // need to reset the line heights because we're messing with the appearance
    &.input-sm {
      line-height: @line-height-small;
    }
    &.input-lg {
      line-height: @line-height-large;
    }
  }
  // Make sure you can't extend textarea outside of bounded area
  textarea& {
    max-width: 100%;
    resize: vertical;
  }
  @media (min-width: @screen-sm-min) {
    height: 38px;
    font-size: inherit;
  }
}

.form-group {
  margin-bottom: 20px;
  label {
    font-size: 12px;
    text-transform: uppercase;
  }
}

.form-group-sm {
  select.form-control {
    line-height: @line-height-small;
    //border-radius: @border-radius-base;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-right: @padding-base-horizontal + 26px;
  }
  .form-control-static {
    font-size: @font-size-base;
    padding-left: 0;
    padding-right: 0;
  }
}
select {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

// forces a form to behave as a horizontal form even on XS screens
// copied from the bootstrap source with media queries removed
.form-horizontal-xs {
  .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: @padding-base-vertical + 1;
  }
  .form-group-lg {
    .control-label {
      padding-top: (@padding-large-vertical * @line-height-large) + 1;
      font-size: @font-size-large;
    }
  }
  .form-group-sm {
    .control-label {
      padding-top: @padding-small-vertical + 1;
      font-size: @font-size-small;
    }
  }
}

@media (min-width: @screen-sm-min) {
  // apply some extra margin to the right of control-labels on inline forms
  .form-inline {
    .control-label {
      margin-right: 5px;
    }
  }
}

// a wrapper for a simple label/input pair that display inline
.form-group-inline {
  > .control-label {
    vertical-align: top;
    margin: (@padding-base-vertical + 1px) 5px 0 0;
  }
  > * {
    display: inline-block;
  }
}

input::-ms-clear {
  display: none;
}
// arbitrary width for inputs we don't want expanding to full width
.input-narrow {
  width: 75px !important;
}

// these are our error messages
// eventually we could fall back in line with bootstrap, but for now make them consistent with web-home
.has-error .help-block.error-block {
  margin-top: 0;
  padding: 3px 9px;
  color: @white;
  background-color: @state-danger-bg;
}

.radio,
.checkbox {
  margin-bottom: 20px;
  label {
    .regular();
    font-size: @font-size-large;
    color: @color-primary-gray;
  }
  &.disabled,
  fieldset[disabled] & {
    label {
      color: @gray-light;
    }
  }
}

// remove negative margin top to add more spacing between sibling controls
.radio + .radio,
.checkbox + .checkbox {
  margin-top: 0;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-left: 30px; // space out consecutive inline controls
}

.help-block {
  color: @gray-dark;
  &.help-block-top {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.help-text {
  .regular;
}

// Show feedback on the left instead of the right
.has-feedback.has-feedback-left {
  // Ensure icons don't overlap text
  .form-control {
    padding-right: 15px; //for the clear button
    padding-left: (@input-height-base * 1.25);
  }
  .form-control-feedback {
    right: inherit;
    left: 0;
  }
}

input[type='text'],
input[type='email'],
input[type='number'] {
  font-weight: 200;
  color: @black;
}

// Stylized Inputs
label.radio-inline,
label.checkbox-inline {
  .label-regular();
  color: @black;
}
.radio,
.radio-inline {
  .input-override {
    .circle();
  }
}
.checkbox,
.checkbox-inline {
  label {
    float: left;
  }
  .input-override {
    border-radius: 4px;
    position: relative;
    i.zolaicon.zolaicon-checkmark {
      position: relative;
      top: 1.5px;
      left: -0.5px;
    }
  }
  .second-label,
  .disabled-label {
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
  }
}
.radio label,
.checkbox label {
  .regular();
}
.radio-inline,
.checklist-inline {
  .regular();
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-left: 20px;
}
.radio,
.checkbox,
.radio-inline,
.checkbox-inline {
  height: 20px;
  .input-override {
    i {
      visibility: hidden;
    }
    .square(20px);
    .all-border;
    cursor: pointer;
    background-color: @white;
    color: @white;
    position: absolute;
    margin-left: -30px;
    display: block;
    font-size: 12px;
    &:hover {
      border-color: @color-black-050;
    }
  }
  label,
  label& {
    padding-left: 30px;
    &.no-label {
      padding-left: 20px;
      > .input-override {
        margin-left: -20px;
        margin-top: 0;
      }
    }
  }
  input[type='radio'],
  input[type='checkbox'] {
    display: none;
  }
  input[type='radio'] + .input-override:before {
    content: '';
    .square(12px);
    .circle();
    background-color: @white;
    position: absolute;
    top: 3px;
    left: 3px;
    margin: auto;
    display: block;
  }
  input[type='radio']:checked + .input-override:before {
    background-color: @color-black-100;
  }
  input[type='checkbox']:checked + .input-override {
    padding: 1px;
    background: @color-black-100;
    color: @white;
    border-color: @color-black-100;
    i {
      visibility: visible;
    }
  }
  &.checkbox-secondary {
    input[type='radio']:checked + .input-override:before,
    input[type='checkbox']:checked + .input-override {
      background: @white;
      color: @color-black-100;
      border-color: @outline-gray;
    }
  }
  &.checkbox-tertiary {
    input[type='radio']:checked + .input-override:before,
    input[type='checkbox']:checked + .input-override {
      background: #8f8f8f;
      color: @white;
      border-color: #8f8f8f;
    }
  }
  input[type='radio']:checked + .input-override:before,
  input[type='checkbox']:checked + .input-override {
    .transition(@checkbox-transition);
  }
}

.file-input-button-container input {
  display: none;
}

.submit-buttons {
  > * {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.input-placeholder-style() {
  color: @secondary-gray;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  font-weight: 200;
}

.form-control {
  &::-webkit-input-placeholder {
    /* Chrome */
    .input-placeholder-style();
  }
  &:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    .input-placeholder-style();
  }
  &:focus:-ms-input-placeholder {
    opacity: 0;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    .input-placeholder-style();
  }
  &:focus::-moz-placeholder {
    opacity: 0;
  }
  &:-moz-placeholder {
    /* Firefox 4 - 18 */
    .input-placeholder-style();
  }
  &:focus:-moz-placeholder {
    opacity: 0;
  }
}

.scroll-select {
  display: block;
  &.with-tooltip {
    display: block;
    font-weight: @font-weight-regular;
  }
  &.inline {
    display: inline-block;
  }
  position: relative;
  &:focus {
    outline: none;
    .btn-dropdown {
      border-color: @primary-teal;
    }
  }
  &.has-error {
    .btn.btn-dropdown.btn-boxed {
      border-color: @red;
    }
  }
  .btn {
    line-height: 1.2;
    text-align: left;
    &.btn-dropdown {
      width: 100%;
    }
  }
  .scroll-value {
    color: black;
    display: inline-block;
    width: calc(~'100% - 10px');
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    position: relative;
    top: 1.5px;
  }
  .scroll-select-dropdown {
    position: absolute;
    width: 100%;
    z-index: 50;
    margin: 8px 0;
    border-radius: 2px;
    .box-shadow(0 6px 12px rgba(0,0,0,0.175));
    overflow-x: auto;
    background-color: @white;
    .scroll-select-option {
      min-width: 150px;
      width: 100%;
      padding: 10px 15px;
      cursor: pointer;
      //line-height: 40px;
      &.scroll-select-active {
        background-color: @color-black-005;
      }
    }
  }
  .btn.btn-dropdown.btn-boxed {
    .zolaicon-chevron-down,
    .zolaicon-chevron-up {
      position: absolute;
      top: 8px;
      right: 0px;
      font-size: 22px;
      color: @color-black-100;
    }
    background-color: @white;
    background-image: none;
    border: 1px solid @outline-gray;
    border-radius: 0px;
    box-sizing: border-box;
    color: @gray-dark;
    cursor: pointer;
    display: inline-block;
    font-family: @body-font-family;
    font-size: 14px;
    font-weight: @font-weight-regular;
    height: 38px;
    letter-spacing: normal;
    line-height: 16.8px;
    margin-bottom: 0px;
    outline-color: @gray-dark;
    outline-style: none;
    outline-width: 0px;
    padding: 8px 12px;
    text-align: left;
    text-size-adjust: 100%;
    text-transform: none;
    touch-action: manipulation;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
