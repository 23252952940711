input,
textarea,
select {
  outline: 0;
}

.form-group {
  label {
    color: @black;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    @media (min-width: @screen-sm-min) {
      font-size: 0.75rem;
    }
  }
  .label-help {
    color: @gray;
    font-weight: 100;
    font-style: italic;
    font-size: @font-size-small;
    padding-left: 10px;
  }
  .day-picker-input-wrapper {
    position: relative;
    i.zolaicon-calendar {
      position: absolute;
      right: 14px;
      top: 9px;
      font-size: 14px;
    }
  }
  .switch-field {
    line-height: 10px;
    .switch-field-input-container {
      height: 38px;
    }
    overflow: hidden;
    display: inline-block;
    margin: 0;
    &:first-child label {
      border-left: 2px solid @primary-teal;
    }
    &:last-child label {
      border-right: 2px solid @primary-teal;
    }
    input {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
      border: 0;
      overflow: hidden;
      &:checked + label {
        color: @white;
        background-color: @primary-teal;
        -webkit-box-shadow: none;
      }
    }

    label {
      float: left;
      display: inline-block;
      width: 100%;
      background-color: @white;
      color: @primary-teal;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      text-shadow: none;
      line-height: 34px;
      border: 2px solid @primary-teal;
      border-left: 1px solid @primary-teal;
      border-right: 1px solid @primary-teal;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition:    all 0.2s ease-in-out;
      -ms-transition:     all 0.2s ease-in-out;
      -o-transition:      all 0.2s ease-in-out;
      transition:         all 0.2s ease-in-out;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .block-not-visible {
    opacity: 0;
    height: 0px;
  }
  .help-block {
    transition: opacity 0s, height 0s;
    transition-delay: 100ms;
  }
  .submitting-button {
      width: 15px;
      margin-left: 12px;
  }
  .loader {
    border: 3px solid lightgray; 
    border-top: 3px solid black; 
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 2s linear infinite;
    position: absolute;
    top: 5px;
    right: 0;
    margin-right: 20px;
  } 
  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
}