@import (reference) '../lib/_bootstrap.less';
@import (reference) '../override/bootstrap/_main.less';
@icon-font-path: '~bootstrap/fonts/';
@body-font-family: 'circular', serif;
@font-weight-regular: 400;
@proxima-semi-bold: 500;

html {
  font-size: 16px;
}

body {
  color: var(--text-primary, @black);
  font-family: @body-font-family;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: @body-font-family;
  font-weight: @font-weight-regular;
  letter-spacing: 0;
}

h2 {
  font-size: 1.875rem;
}
h3 {
  font-size: 1.5rem;
  margin: 20px 0;
}

h4 {
  font-size: 1.125rem;
  font-weight: @proxima-semi-bold;
}

h5 {
  font-size: 1rem;
  font-weight: @proxima-semi-bold;
}

h6 {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

h6.subhead {
  font-size: 0.75rem;
  font-weight: @proxima-semi-bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

p.large {
  font-size: 1.25rem;
  line-height: 22px;
}

p.secondary {
  font-size: 0.75rem;
  line-height: 15px;
}

a {
  color: @primary-teal;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &.cta-link {
    font-size: 0.875rem;
    letter-spacing: 1.2px;
    line-height: 20px;
    text-transform: uppercase;
  }
  &.cta-link.secondary {
    font-size: 0.75rem;
  }
}

/* color helper classes */
.teal {
  color: @primary-teal;
}
