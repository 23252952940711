// https://github.com/twbs/bootstrap/blob/master/less/modals.less
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.modal {
  overflow: scroll;
}

.modal-backdrop {
  // Temporary fix for Bootstrap JS bug
  bottom: 0;
  position: fixed;
}

.modal-content {
  border: none;
  border-radius: 8px;
}

.modal-header {
  padding: 30px 30px 0;
  border-bottom: 0;
  padding-bottom: 0;
}

/*
.modal-body {
  padding: 30px;
}
*/

.modal-footer {
  padding: 0 30px 30px;
  border-top: 0;
  &.modal-footer-dark {
    background-color: @gray-lighter;
  }
  &.modal-footer-centered {
    text-align: center;
  }

  // make sure to remove left margin (that bootstrap applies) on block buttons
  .btn-block + .btn-block {
    margin-left: 0;
  }
  @media (max-width: @screen-xs-max) {
    .btn-xs-block + .btn-xs-block {
      margin-left: 0;
    }
  }

  // standard styles for a modal footer with primary/secondary buttons
  &.modal-footer-buttons {
    > .btn,
    .btn-group {
      margin: 0;
      + .btn,
      .btn-group {
        margin-top: 5px;
      }
      @media (min-width: @screen-sm-min) {
        margin: 0;
        float: right;
        + .btn,
        .btn-group {
          margin-top: 0;
          margin-right: 5px;
        }
      }
    }
    @media (max-width: @screen-xs-max) {
      > .btn {
        display: block;
        width: 100%;
      }
      > .btn-group {
        // TODO: maybe not a good idea to assume .btn-groups are dropdowns
        .block-dropdown-btn;
      }
    }
  }
}

// adding style for modal-close button
.modal-close {
  // reset button styles
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  outline: none;
  // position it
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;

  // size it
  @modal-close-size: 26px;
  font-size: @modal-close-size;
  line-height: 1;
  .square(@modal-close-size);

  // style it
  .thin;
  color: @color-black-100;
  &:hover {
    color: @color-black-075;
  }
}

// for XL screens, expand XL modals
.modal-xl {
  @media (min-width: @screen-md-min) {
    width: @modal-lg;
  }
  @media @screen-xl-media-query {
    width: @modal-xl;
    .modal-content {
      padding: @modal-inner-padding; // add some additional padding for XL modals
    }
  }
}

.bottom-modal-on-mobile {
  .mobile-xs({
    margin: 0;
    position: absolute;
    bottom: 0;
    max-height: 100vh;
    overflow-y: scroll;
    width: 100%;
  });
  .modal-content {
    .mobile-xs({
      border-radius: 8px 8px 0 0;
    });
  }
}
