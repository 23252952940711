.humane,
.humane-zola {
  position: fixed;
  -moz-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  z-index: 100000;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.humane,
.humane-zola {
  @media(max-width: @screen-xs-max) {
    top: 60px;
    width: 100%;
    left: 0;
    margin-left: 0; 
    div {
      width: 100%;
    }
  }
  font-size: 15px;
  // top: 0;
  top: 80px;
  width: 350px;
  text-align:center;
  //opacity: 0;
  text-align: center;
  left: 50%;
  margin-left: -175px;
  .zolaicon {
    padding-right: 10px;
  }
}
.humane p,
.humane-zola p,
.humane ul,
.humane-zola ul {
  margin: 0;
  padding: 0;
}
.humane ul,
.humane-zola ul {
  list-style: none;
}
.humane a,
.humane-zola a {
  color: @white;
  text-decoration: underline;
  text-transform: uppercase;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  &:hover {
    color: @gray;
  }
}
.humane.humane-zola-info div,
.humane-zola.humane-zola-info div {
  background-color: @gray-lighter;
  color: @gray-darker;
  position: relative;
  padding: 15px 20px;
  display: inline-block;
}
.humane.humane-zola-success div,
.humane-zola.humane-zola-success div{
  background-color: @brand-success;
  color: @white;
  position: relative;
  padding: 15px 20px;
  display: inline-block;
}
.humane.humane-zola-error div,
.humane-zola.humane-zola-error div{
  background-color: @brand-danger;
  color: @white;
  position: relative;
  padding: 15px 20px;
  display:inline-block;
}
.humane.humane-zola-confirmation div,
.humane-zola.humane-zola-confirmation div {
  background-color: @brand-alternate-navy;
  color: @white;
  position: relative;
  padding: 15px 20px;
  display: inline-block;
}
.humane-enter,
.humane-zola.humane-zola-enter {
  opacity: 0.1;
}
.humane-enter-active,
.humane-zola.humane-zola-enter-active {
  opacity: 1;
}
.humane-exit,
.humane-zola.humane-zola-exit {
  opacity: 1;
}
.humane-exit-active,
.humane-zola.humane-zola-exit-active {
  opacity: 0.1;
  -moz-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  z-index: 100000;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
