// https://github.com/twbs/bootstrap/blob/master/less/tables.less

.table > thead > tr > th {
  font-size: @font-size-h6;
  text-transform: uppercase;
  color: @gray-dark;
  border-bottom-width: 1px;
}

// restore top border for table header
.table > thead:first-child > tr:first-child > th {
  border-top: solid 1px @table-border-color;
}

// vertically center content
.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        vertical-align: middle;
        border-top: 0;
      }
    }
  }
}
