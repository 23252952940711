// https://github.com/twbs/bootstrap/blob/master/less/mixins.less

// Utilities
@import "mixins/_labels.less";

// Temporary because animatioons don't seem to work with css modules nicely
@-webkit-keyframes pulsetwo {
  70% { box-shadow: 0 0 0 10px rgba(1, 180, 192, 0);}
  100% { box-shadow: 0 0 0 0 rgba(1, 180, 192, 0); }
}
@-o-keyframes pulsetwo {
  70% { box-shadow: 0 0 0 10px rgba(1, 180, 192, 0);}
  100% { box-shadow: 0 0 0 0 rgba(1, 180, 192, 0); }
}
@-moz-keyframes pulsetwo {
  70% { box-shadow: 0 0 0 10px rgba(1, 180, 192, 0);}
  100% { box-shadow: 0 0 0 0 rgba(1, 180, 192, 0); }
}
@keyframes pulsetwo {
  70% { box-shadow: 0 0 0 10px rgba(1, 180, 192, 0);}
  100% { box-shadow: 0 0 0 0 rgba(1, 180, 192, 0); }
}
