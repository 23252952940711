@proxima-semi-bold: 500;

a {
  color: @link-color;
  font-weight: @proxima-semi-bold;

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &.cta-link {
    font-size: 14px;
    letter-spacing: 1.2px;
    font-weight: @proxima-semi-bold;
    line-height: 20px;
    text-transform: uppercase;
  }
  &.cta-link.secondary {
    font-size: 12px;
    line-height: 19px;
  }
}
