// letter spacing
.letter-spacing-lg {
  letter-spacing: @letter-spacing-lg;
}

.letter-spacing-sm {
  letter-spacing: @letter-spacing-sm;
}

// sizing helpers
.text-smaller {
  font-size: @font-size-smaller;
}

.text-small {
  font-size: @font-size-small;
}

.text-base {
  font-size: @font-size-base;
}

.text-large {
  font-size: @font-size-large !important;
}

.text-larger {
  font-size: @font-size-larger;
}

.text-h1 {
  font-size: @font-size-h1-small;
  @media (min-width: @screen-lg-min) {
    font-size: @font-size-h1;
  }
}

.text-h2 {
  font-size: @font-size-h2-small;
  @media (min-width: @screen-lg-min) {
    font-size: @font-size-h2;
  }
}

.text-h3 {
  font-size: @font-size-h3-small;
  @media (min-width: @screen-lg-min) {
    font-size: @font-size-h3;
  }
}

.text-h4 {
  font-size: @font-size-h4-small;
  @media (min-width: @screen-lg-min) {
    font-size: @font-size-h4;
  }
}

.text-h5 {
  font-size: @font-size-h5-small;
  @media (min-width: @screen-lg-min) {
    font-size: @font-size-h5;
  }
}

.text-h6 {
  font-size: @font-size-h6-small;
  @media (min-width: @screen-lg-min) {
    font-size: @font-size-h6;
  }
}

.icon-lg {
  font-size: 45px;
}

.icon-md {
  font-size: 30px;
}

.icon-sm {
  font-size: 20px;
}

// alignment
.text-right {
  text-align: right;
}

//link styles
.link-colored(@color, @hoverColor: @color) {
  color: @color;
  &:hover {
    color: @hoverColor;
    i {
      color: @brand-primary;
    }
  }
  &:active {
    color: @color;
  }
}

.link-white {
  .link-colored(@white);
}

.link-gray {
  .link-colored(@gray-dark, @gray-darker);
}

.link-dark {
  .link-colored(@gray-dark, @brand-primary);
}

//colors
.text-gold {
  color: @brand-gold;
}

.text-navy {
  color: @brand-navy;
}

.text-brand-light {
  color: @brand-light;
}

.text-gray {
  color: @gray-dark;
}

.text-gray-light {
  color: @gray-light;
}

.text-gray-base {
  color: @gray-base;
}

.text-break() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

.danger-asterisk {
  font-size: 20px;
  line-height: 0;
}
