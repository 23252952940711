.content-top {
  .bottom-border();
}

.label-secondary {
  background-color: @brand-alternate-navy;
}

.spoof-header {
  background-color: @brand-danger;
  padding: 10px;
  text-align: center;
  h1 {
    color: @white;
    margin: 0;
  }
}
