// Bootstrap mixins
@import '../../../node_modules/bootstrap/less/mixins.less';
@import '../override/bootstrap/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

// literally just applies a color on hover
.hover-color(@color: @brand-primary) {
  &:hover {
    color: @color;
  }
}

// forces an element to a specific aspect ratio
.force-aspect-ratio(@ratio) {
  height: 0;
  padding-bottom: percentage(@ratio);
}

// line clamp - forces a container with text to fit to a certain number of lines
.line-clamp(@lines, @line-height: @line-height-computed) {
  display: -webkit-box;
  -webkit-line-clamp: @lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: @lines * @line-height;
}

// used for dropdown button groups to force them to expand to full width
// NOTE: only works on browsers the support flexbox
.block-dropdown-btn() {
  display: -webkit-flex;
  display: flex;
  > .btn:first-child {
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
}

// Background sizing mixins
// polyfill is optional because it breaks IE9 in some cases, default case is not to use it
.background-cover(@polyfill: false) {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  & when (@polyfill) {
    -ms-behavior: url(/assets/backgroundsize.htc);
  }
}

.full-bg-img(@img-path) {
  background: url(@img-path) no-repeat center top fixed;
  .background-cover;
}

// Some useful font/typeface related mixins
.thin() {
  font-weight: 100;
}
.regular() {
  font-weight: 400;
}
.semibold() {
  font-weight: @proxima-semi-bold;
}
.bold() {
  font-weight: 700;
}
.italic() {
  font-style: italic;
  .regular;
}

// Border mixins
.all-border() {
  border: solid 1px @gray-border;
}
.bottom-border() {
  border-bottom: solid 1px @gray-border;
}
.top-border() {
  border-top: solid 1px @gray-border;
}
.right-border() {
  border-right: solid 1px @gray-border;
}
.left-border() {
  border-left: solid 1px @gray-border;
}

// logo/icon mixins
.sprite(@width: 0px, @height: 0px, @top: 0px, @left: 0px, @fileNoExtension: '../images/sprite') {
  background-image: url('@{fileNoExtension}.svg');
  background-position: @top @left;
  background-repeat: no-repeat;
  width: @width;
  height: @height;
  .modernizr-no-svg & {
    background-image: url('@{fileNoExtension}.png');
  }
}

.logo(@width, @height, @svgPath, @noSvgPath) {
  .logo-size(@width, @height);
  background-image: url(@svgPath);
  background-repeat: no-repeat;
  .modernizr-no-svg & {
    background-image: url(@noSvgPath);
  }
}

// handles sizing for a logo
// mostly internal to the .logo mixin but can be used independently
.logo-size(@width, @height) {
  background-size: @width @height;
  width: @width;
  height: @height;
}

.logo-retina(@width, @height, @retinaPath, @noRetinaPath) {
  background-repeat: no-repeat;
  width: @width;
  height: @height;
  .img-retina(@noRetinaPath, @retinaPath, @width, @height);
}

.big-quote(@size) {
  font-family: @font-family-base;
  line-height: 1;
  font-size: @size * 2;
  height: @size;
}

// RGBA Background with IE8 fallback
.rgba-background(@hex-color, @alpha, @fallback-color: @hex-color) {
  background: @fallback-color;
  background: fade(@hex-color, @alpha * 100%);
}

// RGBA Outline with IE8 fallback
.rgba-outline(@hex-color, @alpha, @fallback-color: @hex-color) {
  outline-color: @fallback-color;
  outline-color: fade(@hex-color, @alpha * 100%);
}

// make element a circle
.circle() {
  border-radius: 50%;
}

// make element a square with text aligned in middle -- good for icons
.square-icon(@size) {
  width: @size;
  line-height: @size;
}

.retina(@min-width; @rules) {
  @media (min-width: @min-width) and (-webkit-min-device-pixel-ratio: 2),
    (min-width: @min-width) and (min--moz-device-pixel-ratio: 2),
    (min-width: @min-width) and (-o-min-device-pixel-ratio: 2/1),
    (min-width: @min-width) and (min-device-pixel-ratio: 2),
    (min-width: @min-width) and (min-resolution: 192dpi),
    (min-width: @min-width) and (min-resolution: 2dppx) {
    @rules();
  }
}

// Other vendor prefix mixins not covered by Bootstrap
.appearance(@appearance) {
  -moz-appearance: @appearance;
  -webkit-appearance: @appearance;
  appearance: @appearance;
}

.flexbox() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.pulse(@r:255, @g:255, @b:255) {
  box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0.5);
  .animation( pulse 1.5s infinite);
  @-webkit-keyframes pulse {
    70% {
      box-shadow: 0 0 0 10px rgba(@r, @g, @b, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0);
    }
  }
  @-o-keyframes pulse {
    70% {
      box-shadow: 0 0 0 10px rgba(@r, @g, @b, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0);
    }
  }
  @-moz-keyframes pulse {
    70% {
      box-shadow: 0 0 0 10px rgba(@r, @g, @b, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0);
    }
  }
  @keyframes pulse {
    70% {
      box-shadow: 0 0 0 10px rgba(@r, @g, @b, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0);
    }
  }
}

.pulsetwo(@r:255, @g:255, @b:255) {
  box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0.5);
  .animation( pulsetwo 1.5s infinite);
  @-webkit-keyframes pulsetwo {
    70% {
      box-shadow: 0 0 0 10px rgba(@r, @g, @b, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0);
    }
  }
  @-o-keyframes pulsetwo {
    70% {
      box-shadow: 0 0 0 10px rgba(@r, @g, @b, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0);
    }
  }
  @-moz-keyframes pulsetwo {
    70% {
      box-shadow: 0 0 0 10px rgba(@r, @g, @b, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0);
    }
  }
  @keyframes pulsetwo {
    70% {
      box-shadow: 0 0 0 10px rgba(@r, @g, @b, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(@r, @g, @b, 0);
    }
  }
}

.keyframes(@animation-name, @content) {
  @-webkit-keyframes @animation-name {
    @content();
  }
  @-o-keyframes @animation-name {
    @content();
  }
  @-moz-keyframes @animation-name {
    @content();
  }
  @keyframes @animation-name {
    @content();
  }
}

.alert-colored(@background: @brand-primary) {
  background: @background;
  color: @white;
  padding: 10px;
  a {
    color: @white;
    text-decoration: underline;
  }
  p {
    color: @white;
  }
}

.zo-label-font() {
  text-transform: uppercase;
  letter-spacing: @letter-spacing-lg;
  .semibold();
  font-size: @font-size-small;
}

.zo-label() {
  .zo-label-font();
  color: @gray-base;
  padding: 0;
  small {
    text-transform: none;
    color: inherit;
    letter-spacing: @letter-spacing-sm;
  }
}

.label-regular() {
  text-transform: none;
  color: @gray-darker;
  letter-spacing: normal;
  font-size: @font-size-base;
}

.tile-quote (@font-size: @font-size-base) {
  font-family: var(--font-family-serif, @font-family-serif);
  font-weight: @font-weight-medium;
  font-size: @font-size;
  color: @gray-dark;
  font-style: italic;
}

// https://github.com/seven-phases-max/less.curious/blob/master/src/for.less
// ............................................................
// .for

.for(@i, @n) {
  .-each(@i);
}
.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}
.for(@i, @n) when not (@i = @n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

// ............................................................
// .for-each

.for(@array) when (default()) {
  .for-impl_(length(@array));
}
.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}
.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}

.appearance(@appearance) {
  -moz-appearance: @appearance;
  -webkit-appearance: @appearance;
  appearance: @appearance;
}

.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.darken-on-hover(@darken-by) {
  position: relative;
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: @color-black;
    opacity: 0;
    top: 0;
    left: 0;
    .transition(opacity 0.25s ease);
  }
  &:hover {
    &:before {
      opacity: @darken-by;
    }
  }
}

.transform(@transforms) {
  -moz-transform: @transforms;
  -o-transform: @transforms;
  -ms-transform: @transforms;
  -webkit-transform: @transforms;
  transform: @transforms;
}

.filter(@filters) {
  -moz-filter: @filters;
  -o-filter: @filters;
  -ms-filter: @filters;
  -webkit-filter: @filters;
  filter: @filters;
}

.scale(@scale) {
  .transform(scale(@scale));
}

.default-label () {
  font-family: @font-family-base;
  font-size: 16px;
  font-weight: @proxima-semi-bold;
}

.label-regular() {
  text-transform: none;
  color: @gray-darker;
  letter-spacing: normal;
  font-size: @font-size-base;
}

.size(@width; @height) {
  width: @width;
  height: @height;
}

.square(@size) {
  .size(@size; @size);
}

.rgba-background(@hex-color, @alpha, @fallback-color: @hex-color) {
  background: @fallback-color;
  background: fade(@hex-color, @alpha * 100%);
}

.rotate(@degrees) {
  -ms-transform: rotate(@degrees); /* IE 9 */
  -webkit-transform: rotate(@degrees); /* Safari */
  transform: rotate(@degrees);
}

.circle() {
  border-radius: 50%;
}

.animation(@animation) {
  -webkit-animation: @animation;
  -o-animation: @animation;
  -moz-animation: @animation;
  animation: @animation;
}

.retina(@min-width; @rules) {
  @media (min-width: @min-width) and (-webkit-min-device-pixel-ratio: 2),
    (min-width: @min-width) and (min--moz-device-pixel-ratio: 2),
    (min-width: @min-width) and (-o-min-device-pixel-ratio: 2/1),
    (min-width: @min-width) and (min-device-pixel-ratio: 2),
    (min-width: @min-width) and (min-resolution: 192dpi),
    (min-width: @min-width) and (min-resolution: 2dppx) {
    @rules();
  }
}

// Border mixins
.all-border() {
  border: solid 1px @outline-gray;
}
.bottom-border() {
  border-bottom: solid 1px @outline-gray;
}
.top-border() {
  border-top: solid 1px @outline-gray;
}
.right-border() {
  border-right: solid 1px @outline-gray;
}
.left-border() {
  border-left: solid 1px @outline-gray;
}

// used for dropdown button groups to force them to expand to full width
// NOTE: only works on browsers the support flexbox
.block-dropdown-btn() {
  display: -webkit-flex;
  display: flex;
  > .btn:first-child {
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
}

// Hero responsive mixins
.hero-img-position(@heroMobile1x,@heroMobile2x, @heroTablet1x, @heroTablet2x, @heroDesktop1x, @heroDesktop2x, @mobileH: 500px, @tabletH: 400px, @desktopH: 400px) {
  text-align: center;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
  width: 100%;
  background-image: url(@heroMobile1x);
  height: 500px;
  background-size: 500px;
  background-position: top;
  background-repeat: no-repeat;
  margin-bottom: 0;
  padding-bottom: 0;
  .retina(0px, {
    height: @mobileH;
    background-image: url(@heroMobile2x);
  });
  .background-cover;
  @media (min-width: @screen-xs-min) {
    background-image: url(@heroTablet1x);
    height: @tabletH;
    .retina(0px, {
      height: @tabletH;
      background-image: url(@heroTablet2x);
    });
  }
  @media (min-width: @screen-md-min) {
    background-repeat: repeat-x;
    background-image: url(@heroDesktop1x);
    height: @desktopH;
    background-position: center;
    // display: table;
    .retina(0px, {
      height: @desktopH;
      background-image: url(@heroDesktop2x);
    });
  }
}

.busy-spinner() {
  width: 50px;
  height: 50px;
  border: 4px rgba(33, 32, 31, 0.25) solid;
  border-top: 4px @color-black solid;
  border-radius: 50%;
  box-sizing: border-box;
  .animation(spinner 0.6s infinite linear);
}

@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@vertical-nav-width: 74px;
