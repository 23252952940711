// Common Bazaarvoice overrides
// TODO: Once we deploy any Bazaarvoice config changes, this will no longer work (because of changes on their side). We'll need to find another way to hide empty reviews.

div[data-bv-show='rating_summary'] .bv_main_container {
  // Hide the section when there are ratings available
  .bv_stars_component_container .bv_stars_button_container[aria-label='No rating value'] {
    opacity: 0;
  }
  .bv_numReviews_text[aria-label='Read 0 Reviews'] {
    opacity: 0;
  }

  .bv_avgRating {
    font-weight: 400 !important;
  }
  .bv_numReviews_text {
    font-weight: 400 !important;
  }
}

div[data-bv-show='inline_rating'] .bv_main_container .bv_text {
  font-weight: 400 !important;
}