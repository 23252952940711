// https://github.com/twbs/bootstrap/blob/master/less/grid.less

// support "double-gutter" classes
.make-double-gutter(@class) {
  .row-@{class}-double-gutter {
    margin-left: -@grid-gutter-width;
    margin-right: -@grid-gutter-width;
  }
  .col-@{class}-double-gutter {
    padding-left: @grid-gutter-width;
    padding-right: @grid-gutter-width;
  }
  .col-@{class}-double-gutter-left {
    padding-left: @grid-gutter-width;
  }
  .col-@{class}-double-gutter-right {
    padding-right: @grid-gutter-width;
  }
}

.make-triple-gutter(@class) {
  .row-@{class}-triple-gutter {
    margin-left: -@grid-gutter-width * 1.5;
    margin-right: -@grid-gutter-width * 1.5;
  }
  .col-@{class}-triple-gutter {
    padding-left: @grid-gutter-width * 1.5;
    padding-right: @grid-gutter-width * 1.5;
  }
  .col-@{class}-triple-gutter-left {
    padding-left: @grid-gutter-width * 1.5;
  }
  .col-@{class}-triple-gutter-right {
    padding-right: @grid-gutter-width * 1.5;
  }
}

// support "half-gutter" classes
.make-half-gutter(@class) {
  .row-@{class}-half-gutter {
    margin-left: -@grid-gutter-width / 4;
    margin-right: -@grid-gutter-width / 4;
  }
  .col-@{class}-half-gutter {
    padding-left: @grid-gutter-width / 4;
    padding-right: @grid-gutter-width / 4;
  }
  .col-@{class}-half-gutter-left {
    padding-left: @grid-gutter-width / 4;
  }
  .col-@{class}-half-gutter-right {
    padding-right: @grid-gutter-width / 4;
  }
}

// support "no-gutter" classes
.make-no-gutter(@class) {
  .col-@{class}-no-gutter {
    padding-left: 0;
    padding-right: 0;
  }
  .col-@{class}-no-gutter-left {
    padding-left: 0
  }
  .col-@{class}-no-gutter-right {
    padding-right: 0
  }
}

// support row-centering classes for all screen sizes that will force the columns of the row to be centered
// WARNING: this is not well-tested and may cause some undesirable side-effects, use with caution
.make-centered-row(@class) {
  .row-@{class}-centered {
    text-align: center;
    > [class^="col-"] {
      vertical-align: top;
      float: none;
      display: inline-block;
      margin-right: -4px; // is this the right amount?
    }
  }
}

.make-no-gutter(xs);
.make-double-gutter(xs);
.make-triple-gutter(xs);
.make-half-gutter(xs);
.make-centered-row(xs);

@media (min-width: @screen-sm-min) {
  .make-no-gutter(sm);
  .make-double-gutter(sm);
  .make-triple-gutter(sm);
  .make-half-gutter(sm);
  .make-centered-row(sm);
}

@media (min-width: @screen-md-min) {
  .make-no-gutter(md);
  .make-double-gutter(md);
  .make-triple-gutter(md);
  .make-half-gutter(md);
  .make-centered-row(md);
}

@media (min-width: @screen-lg-min) {
  .make-no-gutter(lg);
  .make-double-gutter(lg);
  .make-triple-gutter(lg);
  .make-centered-row(lg);
  .make-half-gutter(lg);
  .container-cap-md {
    width: @container-md;
  }
}

@media @screen-xl-media-query {
  .make-grid(xl);
  .make-no-gutter(xl);
  .make-double-gutter(xl);
  .make-centered-row(xl);
  .container-expand-xl {
    width: @container-lg;
  }
}

// total hack to get columns to 20% width
// we have to impose a max-width on the media queries otherwise they'll override and .col-lg-* classes because they appear later
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .col-sm-fifth {
    width: 20%;
    float:left;
    padding: 0 10px;
  }
}
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .col-md-fifth {
    width: 20%;
    float:left;
    padding: 0 10px;

  }
}
@media (min-width: @screen-lg-min) {
  .col-lg-fifth {
    width: 20%;
    float:left;
    padding: 0 10px;

  }
}

@media (min-width: @screen-sm-min) {
  .col-offset-fifth {
    margin-left: 20%;
  }
}

//** Number of columns in the grid.
@grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width: 20px;
@screen-xl: 1500px;
@screen-xl-min: @screen-xl;

// .container {
//   @media (min-width: @screen-xl-min) {
//     width: 1440px;
//   }
// }
