// https://github.com/twbs/bootstrap/blob/master/less/dropdowns.less

.dropdown {
  // define our own dropdown type that is triggered on hover
  &.dropdown-hover {
    .dropdown-menu {
      margin: 0;
    }
    &:hover,
    &.open {
      .dropdown-menu {
        display: block;
      }
    }
  }
}

.dropdown-menu {
  border: none;
  border-radius: 0;
  padding: 10px 8px;
  @media(min-width: @screen-lg-min) {
    padding: 10px 15px;
  }
}

// Integrate our react dropdown with bootstrap's
.dropdown {
  display: inline-block;
}

.dropdown__content > .dropdown-menu {
  display: none;
  position: absolute;
}

.dropdown--active .dropdown__content > .dropdown-menu- {
  &left,
  &right {
    display: block;
  }
}

.dropdown-menu {
  min-width: 200px;
  margin: 0;
  > .active > a {
    &,
    &:focus {
      color: @brand-primary;
      background-color: transparent;
    }
  }
}

.dropdown-menu {
  background: @white;
  &:extend(.padding-top);
  > li.dark {
    background-color: @gray-lighter;
    margin: -10px 0 -5px;
    border-top: 1px solid @gray-light;
  }
  > li.inner-padding {
    padding: 0;
    a {
      padding: 17px 15px;
      color: @gray-dark;
    }
  }
  > li > a {
    .regular;
    color: @gray-base;
    line-height: 2;
    &:hover {
      color: @gray-base;
      background-color: @highlight-blue;
      .label-primary {
        .label-variant-inverted(@highlight-blue);
      }
    }
  }
}

