.zolaicon-chevron-closed {
  .zolaicon-chevron-down {
    .animation(rotateChevronUp 0.15s forwards);
  }
}

.zolaicon-chevron-open {
  .zolaicon-chevron-down {
    .animation(rotateChevronDown 0.15s forwards);
  }
}

// ROTATE CHEVRON DOWN
@keyframes rotateChevronDown {
  to {
    .transform(rotate(180deg));
  }
}
@-webkit-keyframes rotateChevronDown {
  to {
    .transform(rotate(180deg));
  }
}
@-o-keyframes rotateChevronDown {
  to {
    .transform(rotate(180deg));
  }
}
@-moz-keyframes rotateChevronDown {
  to {
    .transform(rotate(180deg));
  }
}

// ROTATE CHEVRON UP
@keyframes rotateChevronUp {
  from {
    .transform(rotate(180deg));
  }
  to {
    .transform(rotate(0deg));
  }
}
@-webkit-keyframes rotateChevronUp {
  from {
    .transform(rotate(180deg));
  }
  to {
    .transform(rotate(0deg));
  }
}
@-o-keyframes rotateChevronUp {
  from {
    .transform(rotate(180deg));
  }
  to {
    .transform(rotate(0deg));
  }
}
@-moz-keyframes rotateChevronUp {
  from {
    .transform(rotate(180deg));
  }
  to {
    .transform(rotate(0deg));
  }
}
