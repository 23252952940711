// Zola UI styles
@import '~@zola/zola-ui/src/styles/common/_reset.less';
@import '~@zola/zola-ui/build/zola-ui.css';

// Bootstrap base styles
@import './override/customBootstrap.less';

// Bootstrap overrides
@import './override/bootstrap/_main.less';

// Common Bazaarvoice overrides
@import './override/bazaarvoice.less';

// Utils
@import 'util/_main.less';
@import 'util/_mixins.less';
@import 'common/_main.less';

// STYLING FOR NOW

html {
  position: relative;
  min-height: 100%;
  .regular;
}

body {
  background-color: var(--background-screen, @bg-gray);
}

hr {
  height: auto;
}
